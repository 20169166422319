@import './colors.theme.scss';



body 
{
    @each $name, $color in $colors 
    {
        #{$name}: $color;
    }
}