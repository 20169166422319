@use '@angular/material' as mat;
@import 'assets/scss/_reset';
@import 'assets/scss/_variables.scss';
@import '~bootstrap/scss/bootstrap';
@import 'assets/scss/_typals';
// Тема
@import 'assets/scss/theme/style.theme.scss';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$white: (
  //50 : #ffffff,
  //100 : #ffffff,
  //200 : #ffffff,
  //300 : #ffffff,
  //400 : #ffffff,
  //500 : #ffffff,
  //600 : #ffffff,
  //700 : #ffffff,
  //800 : #ffffff,
  //900 : #ffffff,
  //A100 : #ffffff,
  //A200 : #ffffff,
  //A400 : #ffffff,
  //A700 : #ffffff,
  //contrast: (
  //  50 : #000000,
  //  100 : #000000,
  //  200 : #000000,
  //  300 : #000000,
  //  400 : #000000,
  //  500 : #000000,
  //  600 : #000000,
  //  700 : #000000,
  //  800 : #000000,
  //  900 : #000000,
  //  A100 : #000000,
  //  A200 : #000000,
  //  A400 : #000000,
  //  A700 : #000000,
  //)
);

$front-angular-module-primary: mat.define-palette(mat.$grey-palette);
$front-angular-module-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$front-angular-module-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$front-angular-module-theme: mat.define-light-theme(
  $front-angular-module-primary,
  $front-angular-module-accent,
  $front-angular-module-warn
);

//@function my-mat-light-theme-foreground($color) {
//  @return (
//    base:              $color,
//    divider:           $black-12-opacity,
//    dividers:          $black-12-opacity,
//    disabled:          rgba($color, 0.38),
//    disabled-button:   rgba($color, 0.38),
//    disabled-text:     rgba($color, 0.38),
//    hint-text:         rgba($color, 0.38),
//    secondary-text:    rgba($color, 0.54),
//    icon:              rgba($color, 0.54),
//    icons:             rgba($color, 0.54),
//    text:              rgba($color, 0.87),
//    slider-min:        rgba($color, 0.87),
//    slider-off:        rgba($color, 0.26),
//    slider-off-active: rgba($color, 0.38),
//  );
//};
//
//$my-foreground: my-mat-light-theme-foreground(mat-color($white, 700));
//
//$front-angular-module-theme-custom: map-merge($front-angular-module-theme, (foreground: $my-foreground));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($front-angular-module-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

html {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'ALS Hauss' !important;
  color: #fff;
}

button {
  outline: none !important;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop {
  z-index: 10000000 !important;
}

.air-modal {
  .mat-dialog-container {
    background-color: #2f3543;
    padding: 0;
    border-radius: 6px;
  }
}

.settings-modal {
  .mat-dialog-container {
    background-color: #2f3543;
    padding: 0;
    border-radius: 6px;
  }
}

.bg-class {
  background-color: var(--bg-color);
}
