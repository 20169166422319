$colors: (
  --orange-color: #CF9400,
  --orange-btn-bg-hover: #a97900,
  --orange-btn-border-hover: #a97900,
  --bg-color: #09142C,
  --white-transparent-border: rgba(255, 255, 255, 0.1),
  --input-color-focus: rgba(255, 255, 255, 0.5),

  // Header navigation
  --header-nav-bg: transparent,
  --header-nav-color: #FFFFFF,
  --header-nav-active-bg: #454E60,
  --header-nav-active-color: #FFFFFF,
  // Btn colors
  --btn-border-color: #2F3543,
  // Shared
  --header-bg: #172238,
  // Action Btn
  --action-btn-color: #FFFFFF,
  --action-btn-color-hover: #FFFFFF,
  --action-btn-bg-disabled: #303643,
  --action-btn-color-disabled: #ffffff4d,
  --action-btn-border-color-disabled: #303643,
  // Tabs
  --tab-border-color: #2F3543,
  --tab-color: #FFFFFF,
  --tab-color-selected: #FFFFFF,
  // Tables
  --table-field-bg: #09142C,
  --table-field-bg-hover: #FFFFFF10,
  --table-field-border-color: #464C58,
  --table-field-sticky-bg-hover: #182339,
  --table-header-color: #9DA1AB,
  --table-status-draft: #747982,
  --table-status-verify: #00CC57,
  --table-status-warn: #FF3300,
  // Accordion
  --accordion-bg: #172238,
  --accordion-title-color: #FFFFFF,
  --accordion-subtitle-color: #A2A7AF,
  --accordion-disabled-bg: #212a41,
  // Arrow accodion
  --arrow-accordion-bg: transparent,
  --arrow-accordion-title-color: #A3A7AF,
  // Inputs
  --input-color: #FFFFFF,
  --input-border-color: #2E384C,
  --input-placeholder-color: #A3A7AF,
  --input-color-disabled: #8A8F9B,
  // Form field
  --form-field-color-disabled: #A6AAB3,
  --form-field-bg-disabled: #162037,
  --form-field-border-color-disabled: #162037
)
